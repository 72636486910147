@mixin transform-translate($args...) {
	-webkit-transform: translate($args);
	   -moz-transform: translate($args);
	    -ms-transform: translate($args);
	     -o-transform: translate($args);
	        transform: translate($args);
}

@mixin transition($args...) {
	-webkit-transition: $args;
		 -moz-transition: $args;
			-ms-transition: $args;
			 -o-transition: $args;
					transition: $args;
}

@mixin spacing($size, $breakpoint: null) {
	$px: $size;
	$size: $size / 1px;
        
	@if $breakpoint != null {
		$breakpoint: -#{$breakpoint};
	}

	.p#{$breakpoint}-#{$size} { padding: $px!important; }
	.pt#{$breakpoint}-#{$size}, .py#{$breakpoint}-#{$size} { padding-top: $px!important; }
	.pr#{$breakpoint}-#{$size}, .px#{$breakpoint}-#{$size} { padding-right: $px!important; }
	.pb#{$breakpoint}-#{$size}, .py#{$breakpoint}-#{$size} { padding-bottom: $px!important; }
	.pl#{$breakpoint}-#{$size}, .px#{$breakpoint}-#{$size} { padding-left: $px!important; }

	.m#{$breakpoint}-#{$size} { margin: $px!important; }
	.mt#{$breakpoint}-#{$size}, .my#{$breakpoint}-#{$size} { margin-top: $px!important; }
	.mr#{$breakpoint}-#{$size}, .mx#{$breakpoint}-#{$size} { margin-right: $px!important; }
	.mb#{$breakpoint}-#{$size}, .my#{$breakpoint}-#{$size} { margin-bottom: $px!important; }
	.ml#{$breakpoint}-#{$size}, .mx#{$breakpoint}-#{$size} { margin-left: $px!important; }
}

@mixin margin-auto {
	.m-auto { margin: auto !important; }
	.mt-auto, .my-auto { margin-top: auto !important; }
	.mr-auto, .mx-auto { margin-right: auto !important; }
	.mb-auto, .my-auto { margin-bottom: auto !important; }
	.ml-auto, .mx-auto { margin-left: auto !important; }
}

@mixin float($breakpoint: null) {
	@if $breakpoint != null {
		$breakpoint: -#{$breakpoint};
	}

	.float#{$breakpoint}-left { float: left!important; }
	.float#{$breakpoint}-right { float: right!important; }
	.float#{$breakpoint}-none { float: none!important; }
}

@mixin clearfix() {
	&::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin display($breakpoint: null) {
	@if $breakpoint != null {
		$breakpoint: -#{$breakpoint};
	}

	.d#{$breakpoint}-none         { display: none !important; }
	.d#{$breakpoint}-inline       { display: inline !important; }
	.d#{$breakpoint}-inline-block { display: inline-block !important; }
	.d#{$breakpoint}-block        { display: block !important; }
	.d#{$breakpoint}-table        { display: table !important; }
	.d#{$breakpoint}-table-row    { display: table-row !important; }
	.d#{$breakpoint}-table-cell   { display: table-cell !important; }
	.d#{$breakpoint}-flex         { display: flex !important; }
	.d#{$breakpoint}-inline-flex  { display: inline-flex !important; }
}

@mixin text($breakpoint: null) {
	@if $breakpoint != null {
		$breakpoint: -#{$breakpoint};
	}

	.text#{$breakpoint}-left   { text-align: left !important; }
	.text#{$breakpoint}-right  { text-align: right !important; }
	.text#{$breakpoint}-center { text-align: center !important; }
}

@mixin flex($breakpoint: null) {
	@if $breakpoint != null {
		$breakpoint: -#{$breakpoint};
	}

	.flex#{$breakpoint}-row            { flex-direction: row !important; }
	.flex#{$breakpoint}-column         { flex-direction: column !important; }
	.flex#{$breakpoint}-row-reverse    { flex-direction: row-reverse !important; }
	.flex#{$breakpoint}-column-reverse { flex-direction: column-reverse !important; }

	.flex#{$breakpoint}-wrap         { flex-wrap: wrap !important; }
	.flex#{$breakpoint}-nowrap       { flex-wrap: nowrap !important; }
	.flex#{$breakpoint}-wrap-reverse { flex-wrap: wrap-reverse !important; }
	.flex#{$breakpoint}-fill         { flex: 1 1 auto !important; }
	.flex#{$breakpoint}-grow-0       { flex-grow: 0 !important; }
	.flex#{$breakpoint}-grow-1       { flex-grow: 1 !important; }
	.flex#{$breakpoint}-shrink-0     { flex-shrink: 0 !important; }
	.flex#{$breakpoint}-shrink-1     { flex-shrink: 1 !important; }

	.justify-content#{$breakpoint}-start   { justify-content: flex-start !important; }
	.justify-content#{$breakpoint}-end     { justify-content: flex-end !important; }
	.justify-content#{$breakpoint}-center  { justify-content: center !important; }
	.justify-content#{$breakpoint}-between { justify-content: space-between !important; }
	.justify-content#{$breakpoint}-around  { justify-content: space-around !important; }

	.align-items#{$breakpoint}-start    { align-items: flex-start !important; }
	.align-items#{$breakpoint}-end      { align-items: flex-end !important; }
	.align-items#{$breakpoint}-center   { align-items: center !important; }
	.align-items#{$breakpoint}-baseline { align-items: baseline !important; }
	.align-items#{$breakpoint}-stretch  { align-items: stretch !important; }

	.align-content#{$breakpoint}-start   { align-content: flex-start !important; }
	.align-content#{$breakpoint}-end     { align-content: flex-end !important; }
	.align-content#{$breakpoint}-center  { align-content: center !important; }
	.align-content#{$breakpoint}-between { align-content: space-between !important; }
	.align-content#{$breakpoint}-around  { align-content: space-around !important; }
	.align-content#{$breakpoint}-stretch { align-content: stretch !important; }

	.align-self#{$breakpoint}-auto     { align-self: auto !important; }
	.align-self#{$breakpoint}-start    { align-self: flex-start !important; }
	.align-self#{$breakpoint}-end      { align-self: flex-end !important; }
	.align-self#{$breakpoint}-center   { align-self: center !important; }
	.align-self#{$breakpoint}-baseline { align-self: baseline !important; }
	.align-self#{$breakpoint}-stretch  { align-self: stretch !important; }
}

@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}

@mixin img-fluid {
	max-width: 100%;
	height: auto;
}