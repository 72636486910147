.primary-menu {
  border-top: 1px solid #e3e0cd;
  border-bottom: 1px solid #e3e0cd;
  padding: 15px 0;
  ul {
    list-style: none;
    li {
      float: left;
      border-left: 1px solid #e3e0cd;
      &:last-child {
        border-right: 1px solid #e3e0cd;
      }
      a {
        display: block;
        img {
          vertical-align: middle;
        }
      }
    }
  }
}