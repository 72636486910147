// @function vw($target) {
// 	$target: $target / 1px; // remove string px
// 	$vw: ($width * 0.01);
// 	@return ($target / $vw) * 1vw;
// }

// @function per($target) {
// 	$target: $target / 1px; // remove string px
// 	@return ($target * 100%) / $width;
// }

@function rem($target) {
	$rem: $target / $font-size-base;
	@return #{$rem}rem;
}

@function em($target) {
	@return ($target / $font-size-base) * 1em;
}