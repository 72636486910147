* {
  box-sizing: border-box;
}
body {
  font-family: "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
  color: #59483e;
  -webkit-font-smoothing: initial;
  font-size: $font-size-base;
}
.ncontainer {
  width: 980px;
  margin: 0 auto;
}
a {
  text-decoration: none;
  color: #7e7468;
  &:hover, &:focus {
    opacity: 0.7;
    text-decoration: underline;
    color: inherit;
  }
}
.clearfix {
  @include clearfix();
}

// spacing
@include margin-auto();
@include spacing(0px);
@include spacing(10px);
@include spacing(15px);
@include spacing(20px);
@include spacing(30px);
@include spacing(40px);

// display
@include display();

// text
@include text();

// float
@include float();

// flex
@include flex();

// vertical align
.align-baseline    { vertical-align: baseline !important; } // Browser default
.align-top         { vertical-align: top !important; }
.align-middle      { vertical-align: middle !important; }
.align-bottom      { vertical-align: bottom !important; }
.align-text-bottom { vertical-align: text-bottom !important; }
.align-text-top    { vertical-align: text-top !important; }

// images
.img-fluid {
  @include img-fluid();
}

// positions
$positions: static, relative, absolute, fixed, sticky;
@each $position in $positions {
  .position-#{$position} { position: $position !important; }
}

// list-unstyled
.list-unstyled {
  @include list-unstyled();
}