.product {
  .item-title {
    position: relative;
    display: block;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 40px;
      right: 51px;
      line-height: 1.2;
      font-size: 16px;
      font-weight: bold;
      li {
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  ul.item-content {
    list-style: none;
    margin: 0 5px;
    padding: 0;
    li {
      float: left;
      width: 163px;
      margin-top: 20px;
      margin-right: 22px;
      p {
        margin: 0;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        padding-bottom: 7px;
        line-height: 1.47;
        span {
          display: block;
          font-weight: normal;
          font-size: 10px;
          color: #a4937f;
        }
      }
    }
  }
  &.for-gift {
    .item-title {
      a {
        font-size: 11px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
      }
    }
  }
}
.order-from-abroad {
  > div {
    width: 350px;
    border: 1px solid #e3e0cd;
    padding: 20px;
    margin-top: 28px;
  }
}