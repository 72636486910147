// font-size
$font-size-base: 14px;

// body
$body-bg: #ecf0f5;

// header
$header-bg: #3c8dbc;
$header-height: 41px;

// sidebar
$sidebar-width: 160px;
$sidebar-bg: #222d32;
$sidebar-item-bg-hover: #1e282c;
$sidebar-submenu-bg: #2c3b41;
$sidebar-item-bl-active: #3c8dbc;
$sidebar-item-text: #b8c7ce;
$sidebar-item-text-hover: #fff;

// button main
$button-main: #3c8dbc;
$button-main-hover: #2078ab;

// pagination
$paginate-bg-active: #3c8dbc;

// responsive
$bp-xl: 1200px;
$bp-lg: 992px;
$bp-md: 768px;
$bp-sm: 576px;

// product detail
$detail-pink-bold: #ff003c;
$detail-pink: #f5caca;