#sidebar {
  width: 223px;
  float: left;
}
.sidebar-products {
  li {
    border-bottom: 1px dotted #e3e0cd;
    &:last-child {
      border-bottom: 0;
    }
  }
  a:hover {
    text-decoration: none;
  }
  h2 {
    font-size: 12px;
    line-height: 1.2;
    font-family: "Meiryo";
    font-weight: normal;
    padding-right: 15px;
  }
}
.form-sb{
  border: 1px solid #E3E0CD;
  .form-content{
    padding: 10px 10px 15px;
    .form-group{
      label{
        font-weight: bold;
        display: block;
        font-size: 12px;
        color: #59483e;
        margin-bottom: 5px;
      }
      select,input[type="text"]{
        display: block;
        width: 100%;
        padding: 7px 5px 5px;
        border: 1px solid #D9D9D9;
        font-size: 12px;
      }
      select{
        padding: 4px 5px 8px;
      }
      input[type="submit"]{
        display: block;
        width: 100%;
        height: 36px;
        border: none;
        background: url('../img/sidebar/btn_search.png') no-repeat center;
        text-indent: -999;
        outline: none;
        cursor: pointer;
      }

    }
  }
}