// Breadcrumb
.breadcrumb{
  background-color: transparent;
  padding: 0;
  margin-bottom: 15px;
  li{
    font-size: 10px;
    // color: #7e7468;
    &+li{
      &:before{
        content:">";
        padding: 0;
      }
    }
    h1{
      font-size: 10px;
      display: inline-block;
      color: #59483e;
      font-family: "Meiryo", "Hiragino Kaku Gothic ProN", sans-serif;
    }
  }
}

.content-right {
  float: right;
  width: 728px;
}
.btn-gray {
  color: #fff;
  background: gray;
  border-color: gray;
}
.slider{
  .slider-content {
    img {
      max-width: 100%;
    }
    .slick-arrow {
      cursor: pointer;
      width: 32px;
      height: 32px;
    }
    .slick-prev {
      background: url('../img/icon/icon_slider-left_off.svg') no-repeat;
      left: 10px;
      &:hover{
        background: url('../img/icon/icon_slider-left_on.svg') no-repeat;  
      }
    }
    .slick-next {
      background: url('../img/icon/icon_slider-right_off.svg') no-repeat;
      right: 10px;
      &:hover{
        background: url('../img/icon/icon_slider-right_on.svg') no-repeat;
      }
    }
    .slick-dots {
      @include list-unstyled();
      margin: 0;
      text-align: center;
      position: absolute;
      width: 100%;
      bottom: 10px;
      li {
        display: inline-block;
        vertical-align: bottom;
        margin: 0 5px;
        line-height: 0;
        &.slick-active {
          button {
            background: url('../img/icon/icon_slider_pager_on.svg') no-repeat;
          }
        }
      }
      button {
        -webkit-appearance: none;
        outline: 0;
        font-size: 0;
        border: 0;
        padding: 0;
        width: 8px;
        height: 8px;
        background: url('../img/icon/icon_slider_pager_off.svg') no-repeat;
        &:hover,&:focus {
          background: url('../img/icon/icon_slider_pager_on.svg') no-repeat;
        }
      }
    }
  }
  /* .bx-wrapper{
    border: none;
    box-shadow: none;
    margin: 0;
    .bx-prev{
      // background: url('../img/icon/controls.png') no-repeat 0 -32px;
      background: url('../img/icon/icon_slider-left_off.svg') no-repeat;
      &:hover{
        // background-position: 0 0;
        background: url('../img/icon/icon_slider-left_on.svg') no-repeat;  
      }
    }
    .bx-next{
      // background: url('../img/icon/controls.png') no-repeat -43px -32px;
      background: url('../img/icon/icon_slider-right_off.svg') no-repeat;
      &:hover{
        // background-position: -43px 0;
        background: url('../img/icon/icon_slider-right_on.svg') no-repeat;
      }
    }
    .bx-pager{
      bottom: 10px;
      &.bx-default-pager{
        a{
          width: 8px;
          height: 8px;
          // background: #000;
          background: url('../img/icon/icon_slider_pager_off.svg') no-repeat;
          &:hover,&.active,&:focus{
            // background: #ddca90;
            background: url('../img/icon/icon_slider_pager_on.svg') no-repeat;
          }
        }
      }
    }
  } */	
}
.holiday {
  border: 1px solid #59483e;
  padding: 20px;
  color: #59483e;
  h2 {
    text-align: center;
    margin: 0;
    font-family: "Meiryo";
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
  }
  p {
    font-size: 13px;
    line-height: 1.48;
  }
  .sttl {
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 7px;
  }
}
.season {
  > div > a:nth-child(2) {
    margin: 0 23px;
  }
  > div > a{
    width: 227px;
  }
  a {
    background: #fcfbf6;
  }
  p {
    font-size: 10px;
    padding: 15px;
    color: #7e7468;
    line-height: 1.7em;
    b {
      font-size: 14px;
    }
    span {
      color: #a4937f;
    }
  }
}
.ranking {
  .title {
    margin-bottom: 51px;
  }
  > div {
    position: relative;
    border: 1px solid #e3e0dc;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
  .ico {
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
  }
  a {
    p {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      padding-bottom: 7px;
      line-height: 1.37;
      span {
        display: block;
        font-size: 10px;
        color: #a4937f;
        font-weight: normal;
      }
    }
  }
}
.news{
  .list_item{
    padding: 0;
    margin: 0 0 20px;
    &:after{
      content:'';
      clear: both;
      display: block;
    }
    li{
      a{
        display: block;
        &:hover{
          text-decoration: none;
        }
      }
      list-style: none;
      float: left;
      width: 25%;
      padding: 0 26px;
      .img_item{
        border: 1px solid #d7d7d7;
        padding: 5px;
        height: 177px;
        box-sizing: border-box;
        img{
          width: 100%;
          height: 100%;
          max-width: 100%;
          vertical-align: bottom;
        }
      }
      time{
        font-size: 10px;
        display: block;
        text-align: center;
        padding-top: 7px;
      }
      p {
        text-align: center;
        line-height:1.5em;
        font-size: 12px;
        margin: 0;
        padding-top: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .btn_new{
    text-align: right;
  }
}
.form-note {
  margin-top: 5px;
}
.control-label,
.input-sm, .input-group-sm>.form-control, .input-group-sm>.input-group-addon, .input-group-sm>.input-group-btn>.btn {
  font-size: 14px;
}
.w-165 {
  width: 165px!important;
}
.nav-customer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  font-size: 13px;
  a {
    width: (733/5)+px;
    padding: 10px;
    border: 1px solid #e3e0cd;
    height: 58px;
    margin-right: -1px;
    margin-bottom: -1px;
    display: table;
    span {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
    &:hover {
      text-decoration: none;
      background: #f5f5f5;
    }
    &.active {
      background: #ddd;
    }
  }
}

// Product Detail
.product-detail-tab {
  &-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      font-weight: bold;
      border: 1px solid #e3e0cd;
      text-align: center;
      width: 173px;
      padding: 10px;
      &:hover, &:focus {
        text-decoration: none;
      }
      &.active {
        background: #59483e;
        border-color: #59483e;
        color: #fff;
        &:hover, &:focus {
          opacity: 1;
        }
      }
    }
    &-top {
      a {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 0;
      }
    }
    &-bottom {
      a {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top: 0;
      }
    }
  }
  &-content {
    border: 1px solid #e3e0cd;
    margin: -1px 0;
    &-qa {
      dl {
        border-bottom: 1px solid #e3e0cd;
        margin-bottom: 20px;
        padding-bottom: 20px;
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        dt {
          font-weight: bold;
          color: #a4937f;
          margin-bottom: 15px;
          padding-left: 25px;
          position: relative;
          &::before {
            content: 'Q.';
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        dd {
          padding-left: 25px;
          position: relative;
          &::before {
            content: 'A.';
            position: absolute;
            left: 0;
            top: 0;
            font-weight: bold;
          }
        }
      }
    }
    &-voice {
      &-box {
        border: 1px dashed #e3e0cd;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
      }
      &-item {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &-img {
          border-radius: 5px;
          margin-right: 20px;
        }
        &-txt {
          margin-bottom: 0;
          dt {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }
        &-note {
          text-align: right;
          font-size: 11px;
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }
    &-howtouse {
      &-item {
        border-bottom: 1px solid #e3e0cd;
        padding-bottom: 20px;
        display: table;
        width: 100%;
        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        > * {
          display: table-cell;
        }
        dt {
          width: 100px;
        }
      }
    }
    &-product {
      &-item {
        padding: 30px 0;
        border-bottom: 1px solid #e3e0cd;
      }
    }
  }
}
.product-detail-recommended {
  background: #fcfbf6;
  padding: 20px 30px 30px;
  &-title {
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    padding-bottom: 20px;
    margin: 0;
  }
  &-item {
    border: 1px solid #e3e0cd;
    background: #fff;
    width: 322px;
    &:nth-child(n+3){
      margin-top: 20px;
    }
    >div{   
      img.align-middle{
        width: 100%;
      }
    }
    &-txt {
      font-size: 11px;
      font-weight: bold;
      padding: 15px 15px 15px 0;
      & input[type="submit"]{
        width: 185px;
        height: 33px;
        background: url("../img/thumb/product_detail_tab_content_recommended_img1.png") no-repeat center;
        border: none;
        &:hover,&:active,&:focus{
          outline: none;
          background: url("../img/thumb/product_detail_tab_content_recommended_img1.png") no-repeat center;
          box-shadow: none;
        }
      }
    }
    &-info {
      margin-bottom: 5px;
    }
    &-price {
      margin-bottom: 7px;
    }
  }
}
.product-detail-cart{
  &:after{
    content: '';
    clear: both;
    display: block;
  }
  .product-img{
    float: left;
    width: 300px;
    .main-img{
      position: relative;
      height: 300px;
      >img{
        height: 100%;
        border: none;
        padding: 0;
      }
      // .sale-icon{
      //   position: absolute;
      //   right: 5px;
      //   top: 10px;
      //   width: 58px;
      //   img{
      //     margin-bottom: 10px;
      //     max-width: 100%;
      //   }
      // }
    }
    #sub-images-view{
      .img-wrap{
        justify-content: center;
        margin: 0;
        li{
          width: 56px;
          height: 56px;
          margin: 5px;
          >img{
            height: 100%;
            cursor: pointer;
            border: 1px solid #e3e0cd;
            padding: 2px;
          }
        }
      }
    }
  }
  .product-info{
    color: #59483e;
    float: right;
    width: 390px;

    .page-header{
      color: #59483e;
      border: none;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.5;
    }
    >p{
      line-height: 1.7;
    }
    .line-height-sm{
      line-height: 1.4;
    }
    .text-line-small{
      line-height: 1.7;
      display: block;
    }
    .white-space-nowrap{
      white-space: nowrap;
    }
    .size10{
      font-size: 10px;
    }
    .size11{
      font-size: 11px;
    }
    .size14{
      font-size: 14px;
    }
    .size18{
      font-size: 18px;
    }
    .size20{
      font-size: 20px;
    }
    .size23{
      font-size: 23px;
    }
    .size30{
      font-size: 30px;
    }
    .pink-txt-bold{
      color: $detail-pink-bold;
    }
    .pink-txt{
      color: #e47a7c;
    }
    .border-txt{
      border: 1px solid #59483e;
      padding: 3px 10px;
      font-weight: bold;
      &.border-pink{
        border: 1px solid #e47a7c;
        color: #e47a7c;
      }
    }
    .cart-detail{
      padding: 20px;
      border: solid 1px #e3e0cd;
      &.no-border-top{
        border-top: none;
      }
      &.border_4{ 
        border: solid 4px $detail-pink;
      }
      h3{
        font-size: 18px;
        font-weight: bold;
        color: #e47a7c;
        border-bottom: solid 1px $detail-pink;
        padding-bottom: 15px;
      }
      .product-sale-news{
        border: 1px dashed $detail-pink;
        padding: 3px 5px;
        >span{
          display: inline-block;
          margin: 0 5px;
        }
      }
      .product-quantity{
        
        input{
          width: 40px;
          border: 1px solid #e3e0cd;
          outline: none;
          text-align: center;
          padding: 3px;
          margin: 0 5px;
          &::-webkit-inner-spin-button, 
          &::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
          }
        }
        button{
          border: none;
          padding: 0;
          margin: 0;
          background: none;
          outline: none;
          &:hover{
            opacity: 0.8;
          }
        }
      }
      .product-note{
        border: 1px solid #e3e0cd;
        padding: 10px;
      }
      .product-sale-detail{
        .btn-sale-detail{
          background-color: #fcfbf6;
          color: #7e7468;
          padding: 7px 5px;
          font-weight: bold;
          cursor: pointer;
          span{
            .up_icon{
              display: none;
            }
          }
          &.active{
            span{
              .down_icon{
                display: none;
              }
              .up_icon{
                display: inline-block;
              }
            }
            &+.content-sale-detail{
              height: 311px;
            }
          }
        }
        .content-sale-detail{
          height: 0;
          overflow: hidden;
          transition: all 0.5s ease-in-out;
        }
      }
    }

  }
}

.news-gird-view {
  @include clearfix();
  margin: 0 -15px;
  line-height: 1.15;
  li {
    width: (100/4)*1%;
    padding: 0 15px 30px;
  }
  figure {
    border: 1px solid #d7d7d7;
    padding: 5px;
    height: 177px;
    img  {
      max-width: 100%;
      max-height: 100%;
    }
  }
  time {
    display: block;
    margin: 5px 0;
  }
  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.news-detail {
  &__title {
    time { 
      display: block;
      color: #59483e;
      font-weight: normal;
    }
    background: #eeffe2;
    color: #289b41;
    padding: 5px 8px;
    font-weight: bold;
  }
}
.product-search {
  &__list {
    @include clearfix();
    @include list-unstyled();
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    text-align: center;
    li {
      width: (100/3)*1%;
      padding: 0 15px 30px;
    }
    figure {
      height: 177px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
}

a.custom-link {
  color: #2780E3;
}
.w-85 {
  width: 85px;
}
.table-custom th {
  min-width: 90px;
}
p.privacy_desc, .regist-title {
  font-size: 14px;
}

// product related
.products-related {
  margin: 0 25px;
  @include clearfix();
  &__item {
    float: left;
    width: 25%;
    padding: 0 25px;
    &:focus{
      outline: none;
    }
  }
}

// slick slider
.slick-loading .slick-list {
  background: #fff url('../libs/slick/ajax-loader.gif') center center no-repeat;
}
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background: none;
  border: 0;
  font-size: 0;
  width: 25px;
  height: 44px;
  outline: none;
}
.slick-prev {
  left: -25px;
  background: url('../libs/slick/slick-prev.png') no-repeat;
}
.slick-next {
  right: -25px;
  background: url('../libs/slick/slick-next.png') no-repeat;
}
.slick-track {
  margin: 0;
}

// Categories
.recommended{
  .recommended-list{
    >div{
      width: 227px;
      background-color: #fcfbf6;
      margin-right: 22px;
       &:nth-child(n+3){
        margin-right: 0;
      }
      >a{
        color: #7e7468;
        &:hover{
          text-decoration: none;
        }
        p{
          margin: 0;
          padding: 15px;
          span{
            display: block;
            &.title{
              font-weight: bold;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.noticeUnit{
  border: 1px solid #000000;
  padding: 15px;
  p{
    &:last-child{
      margin-bottom: 0;
    }
  }
  hr{
    border-bottom: 1px dashed #000000;
    border-top: none;
  }
}

.categories-product{
  .item{
    background-color: #fcfbf6;
    width: 227px;
    border: 1px solid #e3e0cd;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 22px;
    margin-right: 22px;
    &.halena{
      background-color: #f4f4f4;
      .info-pro{
        >p{
          &:first-child{       
            color: #e1ae18;
            border-bottom: 1px solid #e1ae18;
          }
        }
      }
    }
    &.since{
      background-color: #7e6b5a;
      border: 1px solid #f1e8e3;
      .info-pro{
        >p{
          &:first-child{
            color: #d59069;
            border-bottom: 1px solid #d59069;
          }
        }
      }
      a{
        >p{
          color: #ffffff;
        }
      }
    }
    &:nth-child(3n+3){
      margin-right: 0;
    }
    a{
      text-decoration: none;
    }
    .name-ctg{
      font-weight: bold;
      font-size: 14px;
      color: #7e7468;
      margin-bottom: 5px;
      padding: 15px 15px 0;
    }
    .price{
      color: #e47a7c;
      font-size: 10px;
      padding: 0 15px;
      span{
        font-size: 14px !important;
        font-weight: bold;
      }
    }
    .info-pro{
      padding: 15px 15px 0;
      background-color: #fff;
      // img{
      //   margin-bottom: 5px;
      // }
      >p{
        text-align: center;
        margin: 0;
        &:first-child{
          color: #28a032;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px solid #28a032;
          padding-bottom: 10px;
        }
        &:last-child{
          font-size: 16px;
          color: #7e7468;
        }
      }
    }
  }
}

.ecocert{
  &:after{
    content: '';
    display: block;
    clear: both;
  }

  >div{
    &:first-child{
      width: 255px;
      padding: 0 38px;
    }
    &:last-child{
      width: 470px;
      ul{
        padding: 0 0 0 15px;
        margin: 0;
        > li{
          margin-bottom: 20px;
          color: #28a032;
          &:last-child{
            margin-bottom: 0;
          }
          .ttl-aloby{
            font-weight: bold;
            color: #28a032;
            margin-bottom: 10px;
          }
          p{
            color: #59483e;
            font-weight: normal;
            margin: 0;
          }
          ol{
            padding: 0 0 0 15px;
            >li{
              color: #59483e;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}

// custom info
blockquote > h1 {
  font-size: 14px;
  margin: 0;
}
pre.info_ec {
  color: #59483e!important;
}
blockquote > h1,
code, kbd, pre, samp,
.panel-default>.panel-heading {
  color: #59483e;
  font-family: "Meiryo";
}
.regist-title {
  border-bottom-color: #59483e;
}

// page-top
.page-top {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

// nav-tabs
.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
  color: #59483e;
}