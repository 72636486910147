.header-top {
  background: #f1f1e7;
  .ncontainer {
    height: 35px;
    h1 {
      font-family: "Meiryo";
      font-weight: normal;
      font-size: 12px;
      color: #59483e;
    }
    div {
      font-size: 15.4px;
      font-weight: bold;
    }
    span {
      color: #e47a7c;
    }
  }
}
.header-bottom {
  height: 87px;
}