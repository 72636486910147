#nfooter {
  letter-spacing: 0.5px;
  float: none;
  padding: 0;
  background: #fff;
}
.footer-top {
  font-size: 11px;
  border-top: 1px solid #e3e0cd;
  padding-top: 20px;
  text-align: center;
  a {
    display: inline-block;
    margin-bottom: 20px;
  }
  span {
    padding: 0 4px 0 7px;
  }
  /* ul {
    list-style: none;
    padding: 0;
    li {
      float: left;
      margin-bottom: 20px;
      a {
        color: #5a493f;
        margin: 0 !important;
        padding: 0 !important;
      }
      span {
        padding: 0 7px;
      }
    }
  } */
}
.footer-bottom {
  background: #59483e;
  color: #fff;
  font-size: 12px;
  padding: 20px 0;
  .opening-hours {
    border: 1px solid #7e6b5a;
    padding: 10px;
  }
}